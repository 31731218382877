<template>
	<form class="relative h-full flex flex-col w-full" @submit.prevent="charter">
		<section class="relative flex flex-col overflow-y-auto py-[16px] pb-[160px]">
			<div class="flex gap-[16px] text-lg text-black mb-[20px] cursor-pointer font-bold px-[20px]" @click="step = 2">
				<Icon name="back" class="w-[28px]" />
				Charter shuttle
			</div>

			<div class="flex gap-[12px] justify-between items-center bg-[#f5fffa] p-[20px] max-w-full h-auto">
				<div class="flex items-center w-fit flex-grow ">
					<div class="isolate flex -space-x-5 overflow-hidden p-[8px] w-auto max-w-full " :class="[combineVehicleInfo.images.length === 1 ? '':'md:min-w-[96px]']">
						<img
							v-for="(n,idx) in combineVehicleInfo.images"
							:key="n"
							class="relative  inline-block md:h-[48px] md:w-[48px] h-[36px] w-[36px] rounded-full ring-[2px] ring-[#0DAC5C] object-contain bg-white shrink-0"
							:src="n"
							:class="[idx === 0 ? 'z-50' : 'z-30']"
							alt=""
						>
					</div>
					<span class="flex flex-col flex-grow ml-[10px]">
						<h1 v-for="n in combineVehicleInfo.name" :key="n" class="text-sm font-semibold flex-wrap ">{{ n }}</h1>
						<p class="text-xs">No of vehicles: <span class="font-bold">{{ combineVehicleInfo.count }}</span></p>
					</span>
				</div>

				<div class="flex flex-col max-w-fit flex-grow">
					<span class="text-[#737876] text-[11px]">Starting from</span>
					<h4 class="font-bold text-[#0DAC5C] md:text-base text-sm">
						{{ convertToCurrency(combineVehicleInfo.cost) }}
					</h4>
				</div>
			</div>

			<!-- <div class="flex flex-col px-[20px]">
				<div class="flex gap-[16px]">
					<div class="field relative mt-[24px]">
						<label for="email">First Name</label>
						<input
							id="fname"
							v-model="credentials.fname.value"
							autocomplete="off"
							type="text"
							class="input-field"
							required
						>
					</div>
					<div class="field relative mt-[24px]">
						<label for="email">Last Name</label>
						<input
							id="fname"
							v-model="credentials.lname.value"
							autocomplete="off"
							type="text"
							class="input-field"
							required
						>
					</div>
				</div>
				<div class="flex gap-[16px]">
					<div class="field relative mt-[24px]">
						<label for="email">Email</label>
						<input
							id="fname"
							v-model="credentials.email.value"
							autocomplete="off"
							type="text"
							class="input-field"
							required
						>
					</div>
					<div class="field relative mt-[24px]">
						<label for="email">Phone</label>
						<input
							id="fname"
							v-model="credentials.phone.value"
							autocomplete="off"
							type="text"
							class="input-field"
							required
						>
					</div>
				</div>
			</div> -->
			<div class="w-full relative pr-[16px] px-[20px]">
				<SearchBox />
			</div>

			<section class="px-[16px]">
				<div class="field relative mt-[24px]">
					<label for="email">Departure</label>
					<date-picker class="!w-full" v-model="credentials.pickup_date.value" valueType="format" :disabled-date="startFromTomorrow"></date-picker>
				</div>
				<div class="field relative mt-[24px]">
					<label for="email">Time</label>
					<date-picker class="!w-full" v-model="credentials.pickup_time.value" valueType="format" format="hh:mm a" type="time" placeholder="hh:mm a"></date-picker>
				</div>
				<div class="field relative mt-[24px]" :key="counterComponentKey">
					<label for="email">Number of Passengers</label>
					<div class="input-field">
						<Counter :modelValue="credentials.passengers.value" @change="(val) => updatePassengerCount(val)" />
					</div>
				</div>

				<div class="field relative mt-[24px]">
					<label for="stops">Number of stops</label>
					<input
						id="time"
						v-model="credentials.stopsCount.value"
						autocomplete="off"
						type="number"
						class="input-field"
						min="0"
						@change="credentials.stops.value = []"
					>
				</div>
				<template v-if="credentials.stopsCount.value">
					<div v-for="n in Number(credentials.stopsCount.value)" :key="n" class="field relative mt-[24px]">
						<label for="stops">Stop {{ n }}</label>
						<LocationInput
							:id="n+'stops'"
							autocomplete="off"
							placeholder="Enter location"
							type="text"
							class="input-field"
							required
							@change="credentials.stops.value.push($event)"
						/>
					</div>
				</template>
			</section>

			<ToggleButton :value="credentials.isReturning.value" label="Return trip" name="return_trip" class="mt-[24px] px-[20px]" 
				@changed="(val) => credentials.isReturning.value = val"
			/>

			<section class="px-[16px]">
				<div v-if="credentials.isReturning.value" class="field relative mt-[24px]">
					<label for="email">Return Date</label>
					<date-picker class="!w-full" v-model="credentials.return_date.value" valueType="format" :disabled-date="minimumReturnDate"></date-picker>
				</div>
				<div v-if="credentials.isReturning.value" class="field relative mt-[24px]">
					<label for="email">Time</label>
					<date-picker class="!w-full" v-model="credentials.return_time.value" valueType="format" format="hh:mm a" type="time" placeholder="hh:mm a"></date-picker>
				</div>
			</section>
		</section>
		<footer class="absolute bottom-0 inset-x-0 border-top p-[16px] grid grid-cols-1 items-end gap-[16px] bg-white">
			<button class="auth-form-btn border-none" :disabled="disabled || loading">
				<span v-if="!loading" class="flex justify-center items-center gap-[10px]">Send charter request
				</span>
				<div v-else class="spinner-border" role="status"></div>
			</button>
		</footer>
	</form>
</template>

<script setup>
import {watch, computed} from 'vue'
import { useCharterVehicleUsage, use_post_charter, counterComponentKey } from '../composables'
import { convertToCurrency } from '@/composables/utils'
import Counter from './counter.vue'
import SearchBox from './searchBox.vue'
import LocationInput from './locationInput.vue'
import ToggleButton from './toggleButton.vue'
import Icon from './icon.vue'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import VueToggles from 'vue-toggles'

const { charter, loading, disabled, credentials } = use_post_charter()

const { selectedVehiclesList, step } = useCharterVehicleUsage()

const combineVehicleInfo = computed(() => {
    const nameArr = []
    let totalCount = 0
    const imageArr = []
    let totalCost = 0

    for (const vehicle of selectedVehiclesList.value) {
        nameArr.push(vehicle.name)
		totalCount += vehicle.count
		if (imageArr.length < 2) {
			imageArr.push(vehicle.images[0])
		}

        totalCost += vehicle.count * vehicle.price
    }

    return {
        // name: nameArr.join(' & '),
		name: nameArr,
        count: totalCount,
        images: imageArr,
        cost: totalCost
    }
})

const updatePassengerCount = (num) => {
	credentials.passengers.value = num
	counterComponentKey.value++
}

const startFromTomorrow = (date) => {
	// const today = new Date()
    // today.setHours(0, 0, 0, 0)
	const tomorrow = new Date()
	tomorrow.setDate(tomorrow.getDate() + 1)
	tomorrow.setHours(0,0,0,0)

    return date < tomorrow
}

const minimumReturnDate = (date) => {
	const pickupDate = new Date(credentials.pickup_date.value)
    pickupDate.setHours(0, 0, 0, 0)

    return date < pickupDate
}


watch(credentials.isReturning, () => {
	credentials.return_date.value = ''
	credentials.return_time.value = ''
})

watch(credentials.pickup_date, () => {
	credentials.return_date.value = ''
})
</script>

<style scoped>
h1, h2, h3, h4, h5, h6, p, span{
	margin: 0 !important;
}

.shd {
    box-shadow: 0px 2px 8px 0px rgba(94, 94, 94, 0.12);
}

.field {
    @apply px-[20px]
}

input{
	border: none;
}
</style>
